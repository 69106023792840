<template>
    <div class="skeleton-place-card w-[224px] hbg-white-100 rounded-[10px] shadow-card relative overflow-hidden">
        <div class="h-[148px] w-full hbg-gray-500 animate-pulse"></div>
        <div class="p-2">
            <div class="flex h-10">  
                <div class="h-full w-[34px] hbg-gray-500 animate-pulse rounded-[6px]"></div>
                <!-- reviews -->
                <div class="ml-2">
                    <div class="h-[18px] w-[62px] hbg-gray-500 animate-pulse rounded-[6px]"></div>
                    <div class="h-[18px] w-[62px] hbg-gray-500 animate-pulse rounded-[6px] mt-1"></div>
                </div>
            </div>
            <div class="h-10 mt-2">  
                <div class="h-[18px] w-[80%] hbg-gray-500 animate-pulse rounded-[6px]"></div>
                <div class="h-[18px] w-full hbg-gray-500 animate-pulse rounded-[6px] mt-1"></div>
            </div>
            <div class="flex h-4 mt-2">
                <div class="w-4 h-full hbg-gray-500 animate-pulse rounded-[6px] mr-1"></div>
                <h1 class="h-full hbg-gray-500 animate-pulse rounded-[6px] w-10"></h1>
            </div>
            <div class="flex h-4 mt-2">
                <div class="w-4 h-full hbg-gray-500 animate-pulse rounded-[6px] mr-1"></div>
                <h1 class="h-full hbg-gray-500 animate-pulse rounded-[6px] w-12"></h1>
            </div>
        </div>
    </div>
</template>