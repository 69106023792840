/* eslint-disable */

export const authPath = 'auth'

//users
export const userPath = 'users'

//dashboard
export const dashboardPath = 'dashboard'

export const homePath = 'home'
export const hotelPath = 'hotel'
export const staySurveyPath = 'staySurvey'
export const hotelOtaPath = 'hotelOta'
export const placePath = 'place'
export const experiencePath = 'experience'
export const saasPlacePath = 'saas/place'
export const saasExperiencePath = 'experience/saas'
export const utilityPath = 'utility'
export const cityPath = 'city'
export const stayPath = 'stay'
export const guestPath = 'guest'
export const chatPath = 'chat'
export const utilsPath = 'utils'
export const facilityPath = 'facility'
export const queryPath = 'query'
export const galleryPath = 'gallery'
export const chainPath = 'chain'
export const customizationPath = 'hotel/appearence'
export const querySettingsPath = 'query-settings'
export const stayAccessPath = 'stayAccess'
export const requestSettingsPath = 'request-settings'

// SERVICES API REVIEW
export const reviewPath = 'reviews'
export const translateAndResponsePath = 'translateAndResponse'
export const reviewResponsePath = 'reviewResponses'

//hoster
export const hosterPath = 'hoster'
export const chatSettingsPath = 'settings'
export const notificationsPath = 'notifications'

//platforms
export const platformsPath = 'platforms'

//legal
export const legalPath = 'legal'
